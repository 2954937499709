import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import Popup from './UI/Popup'
import { language } from '../utils'
import Title from './UI/Title'

export default function HelpCenter({ className, showHelp: show, setShowHelp: setShow }) {
  const [filter, setFilter] = useState()
  const { strapi } = useStaticQuery(graphql`
  {
    strapi {
      helps {
        answer
        title
        tags
        locale {
          language
        }
      }
    }
  }
  `)
  const allTags = [... new Set(strapi.helps.filter(h => h.locale?.language === language()).map(h => (h.tags || '').split(';')).flat(1))]
  console.log(allTags)
  return (
    <React.Fragment>
      <div 
        className="flex flex-col justify-center items-center w-full lg:w-64 h-40 lg:h-36 mb-0 cursor-pointer font-bold text-black bg-pink"
        onClick={() => setShow(true)}>
        <img src="/lalalab/sav.png" className="w-24 my-2" />
        <Title variant="large">
          FAQ
        </Title>
      </div>
      {show &&
        <Popup title="Q&#38;A" close={() => setShow(false)}>
          <div className="text-sm mb-2 text-left w-full">Filtres :</div>
          <div className="flex flex-wrap items-start w-full">
            {allTags.map(tag =>
              <div key={tag} className={tag === filter ? "bg-pink border border-pink px-4 py-1 mr-2 mb-2 text-sm cursor-pointer capitalize" : " bg-white border border-pink text-pink px-4 py-1 mr-2 mb-2 text-sm cursor-pointer capitalize"} onClick={() => setFilter(tag)}>{tag}</div>
            )}
          </div>
          <div className="text-left">
            {strapi.helps.filter(h => h.locale?.language === language() && (!filter || (h.tags || '').split(';').includes(filter))).map(({ answer, title, tags }) => (
            <div className="my-5">
              <div className="font-bold recoleta text-lg lg:text-xl mb-1">{title}</div>
              <ReactMarkdown className="text-sm lg:text-lg">{answer}</ReactMarkdown>
            </div>
            ))}
          </div>
        </Popup>
      }
    </React.Fragment>
  )
}
